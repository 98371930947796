body::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: rgb(234, 233, 232);        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: rgb(114, 114, 116);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid rgb(214, 213, 212);  /* creates padding around scroll thumb */
  }
