@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variáveis de cor personalizadas */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 210 40% 96.1%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 346.8 77.2% 49.8%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 188 94% 42%;
    --chart-2: 45 93% 47%;
    --chart-3: 160 84% 39%;
    --chart-4: 351 83% 82%;
    --chart-5: 349 77% 62%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 217.2 32.6% 17.5%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 346.8 77.2% 49.8%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 188 94% 42%;
    --chart-2: 45 93% 47%;
    --chart-3: 160 84% 39%;
    --chart-4: 351 83% 82%;
    --chart-5: 349 77% 62%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply custom-scrollbar;
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer utilities {
  /* Custom scrollbar styling */
  .custom-scrollbar::-webkit-scrollbar {
    width: 0.4em;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    visibility: hidden;
    transition: visibility 0.3s;
  }

  /* Show scrollbar thumb on hover */
  .custom-scrollbar:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  .custom-scrollbar:not(:hover)::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
}
